var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "default-layout",
    [
      _c("SpainhnNavigationBreadcrumb", {
        attrs: { "navigation-links": _vm.links },
      }),
      _c("div", { staticClass: "container-fluid px-5" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-content-around pt-2 mb-3 px-lg-5" },
          [
            _c(
              "div",
              {
                staticClass: "d-flex px-lg-4 mt-4",
                staticStyle: { width: "100%" },
              },
              [
                _c(
                  "icon-base",
                  {
                    attrs: {
                      width: 80,
                      height: 80,
                      iconColor: "rgb(0,0,0)",
                      id: "icono-castillo",
                    },
                  },
                  [_c("icon-casttle")],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "ms-3 h-100 d-flex flex-column text-start" },
                  [
                    _c("p", {
                      staticClass: "mb-0 mt-auto pb-0 spainhn-subtitle-2-black",
                      domProps: { innerHTML: _vm._s(_vm.monumentLanguage) },
                    }),
                    _c(
                      "small",
                      {
                        staticClass: "mb-0 mt-0 spainhn-subtitle-4-black pt-0",
                      },
                      [_vm._v(_vm._s(_vm.address))]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
        _c("div", { staticClass: "d-flex mb-4" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-around px-0 mx-auto w-100 flex-wrap",
            },
            _vm._l(_vm.entradas, function (entrada, index) {
              return _c("cards", {
                key: index,
                attrs: {
                  tipo: _vm.tipo,
                  entradaId: entrada.visitaId,
                  monumentoNombreURL: _vm.monumentoNombre,
                  imageUrl: `${_vm.imagenesUrl}/visitas_castillos/${entrada.oldId}/${entrada.banner}`,
                  title: entrada.nombres[_vm.selectedIdiomaIndex].texto,
                  monument:
                    entrada.castilloNombres[_vm.selectedIdiomaIndex].texto,
                  precioOrientativo: entrada.precioOrientativo
                    ? _vm.$t("default.CARDS.START_FROM", {
                        msg: _vm.getPrice(entrada.precioOrientativo),
                      })
                    : "",
                },
                on: {
                  goToEntrada: function ($event) {
                    return _vm.goToEntrada(entrada)
                  },
                  oToBuy: function ($event) {
                    return _vm.goToContactForm(entrada)
                  },
                },
              })
            }),
            1
          ),
        ]),
      ]),
      _c("ComponentsCloud"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }