<template>
    <default-layout>
        <!-- <div class="spainhn-solid-red-background d-flex" style="min-height: 5vh; width:100%">
            <div class="d-flex ps-5 my-auto container">
                <p class="my-auto pb-0 mb-0 spainhn-small-text" role="button" @click="goToCastillos">{{nombreDeMonumentoPorIdioma}}>> </p>
                <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text"> {{ $t('default.SERVICES_BAR_EXPERIENCES') }} </p>
            </div>
        </div> -->

        <SpainhnNavigationBreadcrumb :navigation-links="links"/>

        <div class="container-fluid px-5">

            <div class="d-flex justify-content-around pt-2 mb-3 px-lg-5" style="">
                <div class="d-flex px-lg-4 mt-4" style="width:100%">
                    <icon-base :width="80" :height="80" 
                        :iconColor="'rgb(0,0,0)'" id="icono-castillo">
                        <icon-casttle></icon-casttle>
                    </icon-base>
                    <div class="ms-3 h-100 d-flex flex-column text-start">
                        <p class="mb-0 mt-auto pb-0 spainhn-subtitle-2-black" v-html="monumentLanguage"></p>
                        <small class="mb-0 mt-0 spainhn-subtitle-4-black pt-0">{{address}}</small>
                    </div>
                </div>
            </div>
            
            <div class="d-flex mb-4">
                <div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap">
                    <cards 
                        v-for="(entrada, index) in entradas" 
                        v-bind:key="index" 
                        @goToEntrada="goToEntrada(entrada)"
                        @oToBuy="goToContactForm(entrada)"
                        :tipo="tipo"
                        :entradaId="entrada.visitaId"
                        :monumentoNombreURL="monumentoNombre"
                        :imageUrl="`${imagenesUrl}/visitas_castillos/${entrada.oldId}/${entrada.banner}`"
                        :title="entrada.nombres[selectedIdiomaIndex].texto" :monument="entrada.castilloNombres[selectedIdiomaIndex].texto" 
                        :precioOrientativo="entrada.precioOrientativo ? $t('default.CARDS.START_FROM', {msg: getPrice(entrada.precioOrientativo) }) : ''"></cards>    
                </div>
            </div>

        </div>
        <ComponentsCloud />
    </default-layout>
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue'
import ApiService from '../services/ApiService';
import IconBase from '../components/IconBase.vue'
import IconCasttle from '../components/icons/IconCasttle.vue';
import Cards from '../components/Cards.vue'
import SpainhnNavigationBreadcrumb from '../components/SpainhnNavigationBreadcrumb.vue';
import ComponentsCloud from '../components/ComponentsCloud.vue';

export default {
    components:{
        DefaultLayout,
        IconBase,
        IconCasttle,
        Cards,
        ComponentsCloud,
        SpainhnNavigationBreadcrumb
    },
    data(){
        return{
            address: null,
            monumentoNombre: null,
            monumentoId: null,
            entradas: [],
            monumentoNombres: [],
            links: [],
            imagenesUrl: null,
            tipo:'Experiencia',
            municipio: "",
            provincia: "",
            comunidad: "",
            nameNavigation: ""
        }
    },
    setup() {
        
    },
    methods:{
        getPrice( precioOrientativo ){
            if( precioOrientativo ){
                if( isNaN(Number(precioOrientativo)) ){
                    return '';
                }else{
                    return `${parseFloat(Number(precioOrientativo))?.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })}`
                }
            }else{
                return '';
            }
        },
        goToContactForm( event ){
            this.$router.push({ name: 'contactReserva', query: {
                monumento: this.nameNavigation,
                tipo: 'Experiencia',
                visita: event.nombres[this.selectedIdiomaIndex ?? 0]?.texto ?? ""
            }})
        },
        goToEntrada(event){
            this.$router.push('/castillos/tarifas/'+this.monumentoNombre+'/'+event.visitaId)
        },
        goToCastillosYPalacios(){
            let $vm = this;
            $vm.$router.push('/castillos')
        },
        goToCastillos(){
            let $vm = this;
            $vm.$router.push('/castillos/' + $vm.$route.params.monumentoNombre)
        },
    },
    mounted(){
        this.imagenesUrl = process.env.VUE_APP_IMAGES_URL;
    },
    computed:{
        selectedIdiomaIndex(){
            let $vm=this;
            let idiomaIndex = 0;
            if($vm.$i18n.locale == 'es'){
                idiomaIndex = 0;
            }
            else if($vm.$i18n.locale == 'en'){
                idiomaIndex = 1;
            }
            else if($vm.$i18n.locale == 'fr'){
                idiomaIndex = 2;
            }
            else if($vm.$i18n.locale == 'de'){
                idiomaIndex = 3;
            }
            return idiomaIndex;
        },
        selectedIdioma(){
            let $vm=this;
            
            return $vm.$i18n.locale;
        },
        monumentLanguage(){
            let $vm = this;

            if( $vm.monumentoNombres[$vm.selectedIdiomaIndex] ){
                return $vm.monumentoNombres[$vm.selectedIdiomaIndex].texto;
            }
            else{
                return $vm.monumentoNombre;
            }
        }
    },
    created(){
        let $vm = this;
        $vm.monumentoNombre = $vm.$route.params.monumentoNombre;
        ApiService.getEdificacion($vm.$i18n.locale, $vm.monumentoNombre)
            .then( result => {
                // console.log(result.data[0]);
                let data = result.data;
                $vm.address = data.direccion;
                $vm.monumentoId = data.id;
                $vm.monumentoNombres = data.nombres;
                $vm.monumentLanguage = data.webName;
                // console.log(data);
                // $vm.edificios = [...result.data];
                    ApiService.getVisitasByEdificacion('es', $vm.monumentoId, "Experiencia")
                        .then( result => {
                            $vm.entradas = [...result.data];

                            const ubicacionData = result.data.reduce( (acc, item ) => {
                                return acc = {
                                    provincia: item.provincia,
                                    comunidad: item.comunidad,
                                    municipio: item.municipio,
                                    nombreMonumento: item.castilloNombres[0].texto
                                };
                            }, { provincia: '', comunidad: '', municipio: '' } );

                            this.provincia = ubicacionData.provincia;
                            this.comunidad = ubicacionData.comunidad;
                            this.municipio = ubicacionData.municipio;
                            this.nameNavigation = ubicacionData.nombreMonumento;

                            this.links = [
                                { text: this.provincia },
                                { text: this.comunidad },
                                { text: this.nameNavigation, navigation: true, navigationLink: "" }
                            ];
                        })
                        .catch( err=> {
                            console.log(err);
                        })
            })
            .catch(err => {
                console.log(err);
            })
    }
}
</script>